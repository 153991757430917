import {_request} from '../http'

export async function newLink(title, description, url, isActive, fromTime, toTime, logo_image_url) {
    const postData = {
        title: title,
        description: description,
        url: url,
        is_active: isActive,
        time: {
            from: fromTime,
            to: toTime
        },
        logo_image_url: logo_image_url
    }
    return _request("POST", "/links", postData)
}

export async function updateLink(id, title, description, url, isActive, fromTime, toTime, logo_image_url) {
    const postData = {
        id: id,
        title: title,
        description: description,
        url: url,
        is_active: isActive,
        time: {
            from: fromTime,
            to: toTime
        },
        logo_image_url: logo_image_url
    }
    return _request("POST", `/links/${id}`, postData)
}

export async function getLinkDetail(id) {
    return _request("GET", `/links/${id}`, null)
}

export async function removeLink(id) {
    return _request("DELETE", `/links/${id}`, null)
}

export async function fetchLinks() {
    return _request("GET", "/links", null)
}

export async function updateSort(list) {
    const postData = {
        list : list
    }
    return _request("POST", "/links/sort", postData)
}