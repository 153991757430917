import {_request} from '../http'


export async function newAdmin(email) {
    const postData = {
        email: email,
    }
    return _request("POST", "/admins", postData)
}

export async function remove(id) {
    return _request("DELETE", `/admins/${id}`, null)
}

export async function fetch() {
    return _request("GET", "/admins", null)
}

