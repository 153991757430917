import {_request} from '../http'


export async function newArticle(title, description, content, url, isActive, fromTime, toTime, image_url, is_featured, featured_info) {
    const postData = {
        title: title,
        description: description,
        content: content,
        url: url,
        is_active: isActive,
        time: {
            from: fromTime,
            to: toTime
        },
        image_url: image_url,
        is_featured: is_featured,
        featured: featured_info
    }
    return _request("POST", "/articles", postData)
}

export async function updateArticle(id, title, description, content, url, isActive, fromTime, toTime, image_url, is_featured, featured_info) {
    const postData = {
        id: id,
        title: title,
        description: description,
        content: content,
        url: url,
        is_active: isActive,
        time: {
            from: fromTime,
            to: toTime
        },
        image_url: image_url,
        is_featured: is_featured,
        featured: featured_info
    }
    return _request("POST", `/articles/${id}`, postData)
}

export async function getArticleDetail(id) {
    return _request("GET", `/articles/${id}`, null)
}

export async function removeArticle(id) {
    return _request("DELETE", `/articles/${id}`, null)
}

export async function fetchArticles() {
    return _request("GET", "/articles", null)
}

export async function updateSort(list) {
    const postData = {
        list : list
    }
    return _request("POST", "/articles/sort", postData)
}