import {_request} from '../http'


export async function newAd(url, isActive, fromTime, toTime, image_url, is_featured, featured_info) {
    const postData = {
        url: url,
        is_active: isActive,
        time: {
            from: fromTime,
            to: toTime
        },
        image_url: image_url,
        is_featured: is_featured,
        featured: featured_info
    }
    return _request("POST", "/ads", postData)
}

export async function updateAd(id, url, isActive, fromTime, toTime, image_url, is_featured, featured_info) {
    const postData = {
        id: id,
        url: url,
        is_active: isActive,
        time: {
            from: fromTime,
            to: toTime
        },
        image_url: image_url,
        is_featured: is_featured,
        featured: featured_info
    }
    return _request("POST", `/ads/${id}`, postData)
}

export async function getAdDetail(id) {
    return _request("GET", `/ads/${id}`, null)
}

export async function removeAd(id) {
    return _request("DELETE", `/ads/${id}`, null)
}

export async function fetchAds() {
    return _request("GET", "/ads", null)
}

export async function updateSort(list) {
    const postData = {
        list : list
    }
    return _request("POST", "/ads/sort", postData)
}