import React from "react";
import * as api from '../../services/app'
import { _upload } from '../../services/http.js'
import GeoTarget from "./GeoTarget";

export default function AppForm(props) {

    const [input, setInput] = React.useState(
        {
            id: "",
            index: 0,
            title: "",
            description: "",
            url: "",
            is_active: true,
            is_link_enabled: false,
            fromDate: "2020-01-01",
            fromTime: "00:00:00",
            toDate: "2030-01-01",
            toTime: "00:00:00",
            logo_image_url: "",
            //this field for posting
            time: {
                from: 0,
                to: 0
            },
            //when app is featured
            is_featured: false
        });

    const [featured, setFeatured] = React.useState({
        title: "",
        subtitle: "",
        image_url: "",
    })
    //a list of country code. iso 3166-1 alpha 2. e.g. us, jp.
    const [geoTarget, setGeoTarget] = React.useState(null);

    const [advanceMode, setAdvanceMode] = React.useState(false);

    const handleFeaturedInputChange = (e) => {
        const { name, value } = e.target
        if (e.target.type === "checkbox") {
            setFeatured({ ...featured, [name]: e.target.checked })
            return
        }
        setFeatured({ ...featured, [name]: value })
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        if (e.target.type === "checkbox") {
            setInput({ ...input, [name]: e.target.checked })
            return
        }
        setInput({ ...input, [name]: value })
    }

    const deleteItem = (e) => {
        e.preventDefault();
        api.removeApp(input.id)
            .then(data => {
                props.history.push('/apps')
            })
            .catch(error => {
                alert(error);
            })
    }

    const save = (e) => {
        e.preventDefault();

        if (input.fromDate !== "" && input.fromTime) {
            let unixTimeMilliseconds = Date.parse(`${input.fromDate} ${input.fromTime} UTC`);
            if (isNaN(unixTimeMilliseconds) === false) {
                let unixtimestamp = unixTimeMilliseconds / 1000;
                input.time.from = unixtimestamp
            }
        }

        if (input.toDate !== "" && input.toTime) {
            let unixTimeMilliseconds = Date.parse(`${input.toDate} ${input.toTime} UTC`);
            if (isNaN(unixTimeMilliseconds) === false) {
                let unixtimestamp = unixTimeMilliseconds / 1000;
                input.time.to = unixtimestamp
            }
        }

        onSave(input);
    }

    function loadData(id) {
        api.getAppDetail(id)
            .then(data => {
                //parse date time here
                data.fromDate = timestampToDate(data.time.from)
                data.fromTime = timestampToTime(data.time.from)
                data.toDate = timestampToDate(data.time.to)
                data.toTime = timestampToTime(data.time.to)
                setInput(data)
                if (data.featured) {
                    setFeatured(data.featured)
                }
                
                if (data.hasOwnProperty("geo_target")) {
                    console.log(data.geo_target.excludes);
                    if (data.geo_target != null) {
                        setGeoTarget(data.geo_target);
                    }
                    
                }

            })
            .catch(error => {
                // console.log(error);
                props.history.push('/apps');
            })
    }

    React.useEffect(() => {
        if (props.match.params.id !== "new") {
            loadData(props.match.params.id)
        }
    }, [props.match.params.id]);

    function onSave(app) {
        //check if id is assigned by server
        if (app.id !== "") {
            //update
            api.updateApp(app.id, app.title, app.description, app.url, app.is_active, app.is_link_enabled, app.time.from, app.time.to, app.logo_image_url, app.is_featured, featured, geoTarget)
                .then(data => {
                    props.history.push('/apps')
                })
                .catch(error => {
                    alert(error);
                })
        } else {
            //new 
            api.newApp(app.title, app.description, app.url, app.is_active, app.is_link_enabled, app.time.from, app.time.to, app.logo_image_url, app.is_featured, featured, geoTarget)
                .then(data => {
                    props.history.push('/apps')
                })
                .catch(error => {
                    alert(error);
                })
        }
    }

    function timestampToTime(unixTimestamp) {
        let now = new Date();

        if (unixTimestamp !== 0) {
            now = new Date(unixTimestamp * 1000);
        }

        let hours = now.getUTCHours().toString().padStart(2, '0');
        let minutes = now.getUTCMinutes().toString().padStart(2, '0');
        let seconds = now.getUTCSeconds().toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    }

    function timestampToDate(unixTimestamp) {
        let now = new Date();
        if (unixTimestamp !== 0) {
            now = new Date(unixTimestamp * 1000);
        }

        let year = now.getUTCFullYear().toString().padStart(2, '0');
        let month = (now.getUTCMonth() + 1).toString().padStart(2, '0');  // Months in JavaScript are returned as a 0 based value.
        let date = now.getUTCDate().toString().padStart(2, '0');
        return `${year}-${month}-${date}`
    }

    const onFileSelect = (e) => {
        const { name } = e.target
        var f = e.target.files[0];
        let formData = new FormData();
        formData.append("file", f);
        _upload("POST", "/image/upload", formData)
            .then(function (res) {
                setInput({ ...input, [name]: res.result.data })
            }, function (e) {
                alert("Error submitting form!");
            });
    }

    const onFeaturedFileSelect = (e) => {
        const { name } = e.target
        var f = e.target.files[0];
        let formData = new FormData();
        formData.append("file", f);
        _upload("POST", "/image/upload", formData)
            .then(function (res) {
                setFeatured({ ...featured, [name]: res.result.data })
            }, function (e) {
                alert("Error submitting form!");
            });
    }
    
    const handleSelectGeo = (options) => {
        let list = options.map((item)=> {
            return {
                country_code: item
            }
        })
        setGeoTarget({excludes: list});
    }

    return (
        <div>
            <div className="header">
                <div className="container-fluid">
                    <div className="header-body">
                        <div className="row align-items-end">
                            <div className="col">
                                <h1 className="header-title">Apps/Services</h1>
                            </div>
                            <div className="col-auto">
                                <button className="btn btn-sm btn-outline-secondary" onClick={(e) => { props.history.goBack() }}><i className="fe fe-chevron-left"></i>  Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <form onSubmit={save}>
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">


                                    <h4 className="card-header-title">Featured app 🎉</h4>

                                </div>
                                <div className="col-auto mr-n3">


                                    <span className="text-muted">Featured app: </span>

                                </div>
                                <div className="col-auto">


                                    <div className="custom-control custom-switch">
                                        <input type="checkbox" name="is_featured" className="custom-control-input" id="cardToggle" checked={input.is_featured} onChange={handleInputChange} />
                                        <label className="custom-control-label" htmlFor="cardToggle"></label>
                                    </div>

                                </div>
                            </div>

                        </div>
                        {
                            input.is_featured ?
                                <div className="card-body">

                                    <div className="form-group">
                                        <label>
                                            Title <span className="text-danger">*</span>
                                        </label>
                                        <input type="text" name="title" className="form-control" value={featured.title} onChange={handleFeaturedInputChange} required />
                                    </div>

                                    <div className="form-group">
                                        <label>
                                            Subtitle <span className="text-danger">*</span>
                                        </label>
                                        <input type="text" name="subtitle" className="form-control" value={featured.subtitle} onChange={handleFeaturedInputChange} required />
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label>Featured Image URL <span className="text-danger">*</span> <span className="small text-muted">4x5 ratio</span></label>
                                                <input type="text" name="image_url" className="form-control" value={featured.image_url} onChange={handleFeaturedInputChange} required />
                                            </div>
                                        </div>
                                        <div className="col-auto">
                                            <div className="form-group">
                                                <label>&nbsp;</label>
                                                <div className="fallback">
                                                    <div className="custom-file">
                                                        <input type="file" name="image_url" className="custom-file-input" id="featuredImageUpload" accept="image/*" onChange={onFeaturedFileSelect} />
                                                        <label className="custom-file-label" htmlFor="featuredImageUpload">Choose image file</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }

                    </div>
                    <GeoTarget didSelect={handleSelectGeo} selected={geoTarget}></GeoTarget>
                    <div className="card">
                        <div className="card-body">
                            <h3>Detail</h3>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>
                                            Icon Image URL <span className="small text-muted">min 64x64px</span>
                                        </label>
                                        <input type="text" name="logo_image_url" className="form-control" value={input.logo_image_url} onChange={handleInputChange} required />
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="form-group">
                                        <label>
                                            &nbsp;
                                </label>
                                        <div className="fallback">
                                            <div className="custom-file">
                                                <input type="file" name="logo_image_url" className="custom-file-input" id="projectCoverUploads" accept="image/*" onChange={onFileSelect} />
                                                <label className="custom-file-label" htmlFor="projectCoverUploads">Choose image file</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="form-group">
                                <label>
                                    Title <span className="text-danger">*</span>
                                </label>
                                <input type="text" name="title" className="form-control" value={input.title} onChange={handleInputChange} required />
                            </div>
                            <div className="row">
                                <div className="col">
                                    <div className="form-group">
                                        <label>
                                            Short description
                            </label>
                                        <input type="text" name="description" className="form-control" value={input.description} onChange={handleInputChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>
                                    URL <span className="text-danger">*</span>
                                </label>
                                <input type="url" name="url" className="form-control" value={input.url} onChange={handleInputChange} required />
                            </div>


                            <div className="row">
                                <div className="col col-6">
                                    <div className="form-group">
                                        <label className="mb-1">
                                            Bitcoin.com Link Enabled
                                        </label>
                                        <small className="form-text text-muted">
                                            Check this if the app supports Bitcoin.com Link
                                        </small>
                                        <div className="row">
                                            <div className="col-auto">
                                                <div className="custom-control custom-switch">
                                                    <input name="is_link_enabled" checked={input.is_link_enabled} onChange={handleInputChange} type="checkbox" className="custom-control-input"
                                                        id="switchLinkEnabled" />
                                                    <label className="custom-control-label" htmlFor="switchLinkEnabled">Enabled</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-6">
                                    <div className="form-group">
                                        <label className="mb-1">
                                            Active
                                        </label>
                                        <small className="form-text text-muted">
                                            If active, data will show up on every device globally
                                        </small>
                                        <div className="row">
                                            <div className="col-auto">
                                                <div className="custom-control custom-switch">
                                                    <input name="is_active" checked={input.is_active} onChange={handleInputChange} type="checkbox" className="custom-control-input"
                                                        id="switchActive" />
                                                    <label className="custom-control-label" htmlFor="switchActive">Active</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="row">
                                <div className="col">
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox" className="custom-control-input" id="customSwitch1" onChange={(e) => { setAdvanceMode(!advanceMode) }} />
                                        <label className="custom-control-label" htmlFor="customSwitch1">Advance mode</label>
                                    </div>
                                </div>
                            </div>

                            {
                                advanceMode ?
                                    <div className="mt-4">
                                        <h4>Advance mode</h4>
                                        <p className="text-muted">Specify start date and end date</p>
                                        <div className="row">
                                            <div className="col col-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="mb-1">
                                                        From date <span className="text-danger">*</span> <span className="small text-muted">(ISO format year-month-date e.g. 2018-11-15)</span>
                                                    </label>
                                                    <input type="date" className="form-control" name="fromDate" value={input.fromDate} onChange={handleInputChange} required />
                                                </div>
                                            </div>
                                            <div className="col col-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="mb-1">
                                                        Time <span className="text-danger">*</span> <span className="small text-muted">(hour:minute:second in 24-hour format GMT e.g. 16:40:00)</span>
                                                    </label>
                                                    <input type="text" className="form-control" name="fromTime" value={input.fromTime} onChange={handleInputChange} required />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col col-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="mb-1">
                                                        To date <span className="text-danger">*</span> <span className="small text-muted">(ISO format year-month-date e.g. 2018-11-15)</span>
                                                    </label>
                                                    <input type="date" className="form-control" name="toDate" value={input.toDate} onChange={handleInputChange} required />
                                                </div>
                                            </div>
                                            <div className="col col-6 col-lg-6">
                                                <div className="form-group">
                                                    <label className="mb-1">
                                                        Time <span className="text-danger">*</span> <span className="small text-muted">(hour:minute:seconds in 24-hour format GMT e.g. 16:40:00)</span>
                                                    </label>
                                                    <input type="text" className="form-control" name="toTime" value={input.toTime} onChange={handleInputChange} required />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                            }




                        </div>
                        <div className="card-footer row">
                            <div className="col">
                                <button type="submit" className="btn btn-primary mr-2">Save</button>
                                <button type="button" className="btn btn-outline-secondary" onClick={(e) => { props.history.goBack() }}>Cancel</button>
                            </div>
                            {
                                input.id
                                    ? <div className="col  text-right"><button type="button" className="btn btn-outline-danger" onClick={deleteItem}>Delete</button></div>
                                    : null
                            }

                        </div>

                    </div>
                </form>
            </div>
        </div>
    );
}
