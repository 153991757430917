import React from "react";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Link } from "react-router-dom";
import { arrayMoveImmutable as arrayMove } from 'array-move';

export default function List(props) {

    const [data, setData] = React.useState({ links: [] });

    React.useEffect(() => {
        setData({ links: props.list })
    }, [props.list]);

    function parseTimestamp(unixTimestamp) {
        var date = new Date();

        if (unixTimestamp !== 0) {
            date = new Date(unixTimestamp * 1000);
        }

        return date.toUTCString()
    }

    const SortableItem = SortableElement(({ link, last }) => (
        <div key={link.id}>
            <div className="row align-items-center">
                <div className="col-auto">
                    <img src={link.logo_image_url} alt="" height="40"></img>
                </div>
                <div className="col">
                    <h4 className="card-title mb-2 name"> <Link key={link.id} to={`/links/${link.id}`}>{link.title}</Link> {
                        link.is_active ?
                            <span className="badge badge-soft-primary text-uppercase">Active</span>
                            :
                            <span className="badge badge-soft-secondary text-uppercase">Inactive</span>
                    }
                    </h4>
                    <p className="card-text small text-secondary mb-1">{link.url}</p>
                    {
                        link.description === "" ?
                            null
                            :
                            <p className="card-text small text-muted">{link.description}</p>
                    }
                    <p className="card-text small text-muted">{parseTimestamp(link.time.from)} – {parseTimestamp(link.time.to)}</p>
                </div>
                <div className="col-auto">
                    {
                        props.editSorting ?
                        <button className="btn text-muted"><span className="fe fe-align-justify"></span></button>
                        :
                        <Link key={link.id} className="btn btn-light btn-sm" to={`/links/${link.id}`}>edit</Link>
                    }

                </div>
            </div>
            {
                last ?
                    null
                    : <hr></hr>
            }
        </div>)
    );

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div>
                {items.map((link, index) => (
                    <SortableItem disabled={!props.editSorting} key={`item-${link.id}`} index={index} link={link} last={index === items.length - 1} />
                ))}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        var list = data.links;
        list = arrayMove(list, oldIndex, newIndex);
        setData({ links: list });
        props.onSort(list)
    };

    return (
        <div className="card">
            <div className="card-body">
                {/* distance={2} is very important as it will make any link in a row clickable */}
                <SortableList distance={2} items={data.links} onSortEnd={onSortEnd}></SortableList>
            </div>
        </div>
    );
}
