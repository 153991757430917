import React from "react";
import * as Constants from "../../constants";

function Login(props) {
    const [gsiLoaded, setGsiLoaded] = React.useState(false);
    const googleButtonRef = React.useRef();

    React.useEffect(() => {
        console.log("mounted login");
    }, []);

    React.useEffect(() => {
        if (gsiLoaded) {
            return;
        }

        const responseGoogle = (response) => {
            //validate this token again with the server
            fetch(Constants.API_BASE_URL + "/verifyToken", {
                method: "get",
                headers: new Headers({
                    Authorization: "Bearer " + response.credential,
                    "Content-Type": "application/json",
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    //save user state and access token here.
                    props.authorized(data, response.credential);
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        const initGsi = () => {
            if (!window.google || gsiLoaded || !googleButtonRef.current) {
                return;
            }

            window.google.accounts.id.initialize({
                client_id: Constants.GOOGLE_CLIENT_ID,
                callback: responseGoogle,
            });

            window.google.accounts.id.renderButton(googleButtonRef.current, {
                theme: "outline",
            });

            setGsiLoaded(true);
        };

        if (!window.google) {
            const script = window.document.createElement("script");
            script.src = "https://accounts.google.com/gsi/client";
            script.async = true;
            script.defer = true;

            window.document.head.appendChild(script);

            script.addEventListener("load", initGsi);

            return () => {
                script.removeEventListener("load", initGsi);
            };
        }

        return undefined;
    });

    React.useEffect(() => {
        let renderButton;
        let buttonRendered;

        try {
            renderButton = window.google.accounts.id.renderButton;
            buttonRendered = googleButtonRef.current.hasChildNodes();
        } catch (e) {
            return;
        }

        if (!renderButton || buttonRendered) {
            return;
        }

        renderButton(googleButtonRef.current, {
                theme: "outline",
            });
    }, [gsiLoaded]);

    return (
        <div className="d-flex align-items-center py-7">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-5 col-xl-4">
                        <h1 className="display-4 text-center mb-3">Sign in</h1>
                        <p className="text-muted text-center mb-5">
                            Bitcoin.com Wallet Dashboard
                        </p>
                        <div
                            ref={googleButtonRef}
                            className="d-flex justify-content-center"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
