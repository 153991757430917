import React from 'react'

export default function AdminForm(props) {

  const [input, setInput] = React.useState(
    {
      email: ""
    });


  const handleInputChange = (e) => {
    const { name, value } = e.target
    setInput({ ...input, [name]: value })
  }

  const didTapSave = (e) => {
    e.preventDefault();
    if (input.email.trim().length === 0) {
      return;
    }
    props.onSave(input.email.trim())
    setInput({email: "" })
    document.getElementById("close").click();
  }

  const checkEnableButton= () => {
    return input.email.trim().length === 0
  }
  return (
    <form onSubmit={didTapSave}>
    <div className="modal fade" id="admin-form" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Add new admin</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
          
            <div className="form-group">
              <label>
                Bitcoin.com Email <span className="text-danger">*</span>
              </label>
              <input type="text" name="email" placeholder="only @bitcoin.com email is allowed" className="form-control" value={input.email} onChange={handleInputChange} required />

            </div>

          </div>
          <div className="modal-footer">
            <button type="button" id="close" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="submit" disabled={checkEnableButton()} className="btn btn-primary">Add</button>
          </div>
        </div>
      </div>
    </div>
    </form>
  );
}