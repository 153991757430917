
var url;
if (process.env.REACT_APP_BUILD == 'prod') {
    url = "https://wallet-dashboard-api.cloud.bitcoin.com/dashboard_api/v1";
} else if (process.env.REACT_APP_BUILD === 'local') {
    url = "http://localhost:8888/dashboard_api/v1";
} else if (process.env.REACT_APP_BUILD === 'develop') {
    url = "https://dashboard-api-dev.test.cloud.bitcoin.com/dashboard_api/v1";
}

export const API_BASE_URL = url;

export const GOOGLE_CLIENT_ID =
    "1029290530001-klrhhhv0e96le42264qcjj504foun5kh.apps.googleusercontent.com";
