import React from "react";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Link } from "react-router-dom";
import { arrayMoveImmutable as arrayMove } from 'array-move';

export default function List(props) {

    const [data, setData] = React.useState({ apps: [] });

    React.useEffect(() => {
        setData({ apps: props.list })
    }, [props.list]);

    function parseTimestamp(unixTimestamp) {
        var date = new Date();

        if (unixTimestamp !== 0) {
            date = new Date(unixTimestamp * 1000);
        }

        return date.toLocaleString()
    }

    const SortableItem = SortableElement(({ app, last }) => (
        <div key={app}>
            <div className="row align-items-center">
                <div className="col-auto">
                    {
                        props.editSorting ?
                        null
                        : 
                        <img src={app.logo_image_url} alt="" height="40"></img>
                    }
                    
                </div>
                <div className="col">
                    <h4 className="card-title mb-2 name"> <Link key={app.id} to={`/apps/${app.id}`}>{app.title}</Link>
                    {
                        app.is_featured ?
                            <span className="badge badge-soft-success text-uppercase ml-2">Featured</span>
                            :
                           null
                    }
                     {
                        app.is_active ?
                            null
                            :
                            <span className="badge badge-soft-secondary text-uppercase ml-2">Inactive</span>
                    }
                     {
                        app.is_link_enabled ?
                            <span className="badge badge-soft-info text-uppercase ml-2">Link enabled</span>
                            :
                           null
                    }
                    {
                        app.geo_target && app.geo_target.excludes ?
                            <span className="badge badge-soft-warning text-uppercase ml-2">Geo Block</span>
                            :
                           null
                    }
                    </h4>
                    <p className="card-text small text-secondary mb-1"><a href={app.url} rel="noopener noreferrer" target="_blank">{app.url}</a></p>
                    {
                        app.description === "" ?
                            null
                            :
                            <p className="card-text small text-muted mb-2">{app.description}</p>
                    }
                    {
                        app.updated.email ?
                        <p className="card-text small text-muted">Updated by {app.updated.email} • {parseTimestamp(app.updated.timestamp)}</p>
                        :
                        <p className="card-text small text-muted">Created by {app.created.email} • {parseTimestamp(app.created.timestamp)}</p>
                    }

                    {/* <p className="card-text small text-muted">{parseTimestamp(app.time.from)} – {parseTimestamp(app.time.to)}</p> */}
                </div>
                <div className="col-auto">
                    {
                        props.editSorting ?
                        <button className="btn text-muted"><span className="fe fe-align-justify"></span></button>
                        :
                        <Link key={app.id} className="btn btn-light btn-sm" to={`/apps/${app.id}`}>edit</Link>
                    }

                </div>
            </div>
            {
                last ?
                    null
                    : <hr></hr>
            }
        </div>)
    );

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div>
                {items.map((app, index) => (
                    <SortableItem disabled={!props.editSorting} key={`item-${app.id}`} index={index} app={app} last={index === items.length - 1} />
                ))}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let list = data.apps;
        list = arrayMove(list, oldIndex, newIndex);
        setData({ apps: list });
        props.onSort(list)
    };

    return (
        <div className="card">
            <div className="card-body">
                {/* distance={2} is very important as it will make any link in a row clickable */}
                <SortableList distance={2} items={data.apps} onSortEnd={onSortEnd}></SortableList>
            </div>
        </div>
    );
}
