import React from "react";
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Link } from "react-router-dom";
import { arrayMoveImmutable as arrayMove } from 'array-move';

export default function AdList(props) {

    const [data, setData] = React.useState({ list: [] });

    React.useEffect(() => {
        setData({ list: props.list })
    }, [props.list]);

    function parseTimestamp(unixTimestamp) {
        var date = new Date();

        if (unixTimestamp !== 0) {
            date = new Date(unixTimestamp * 1000);
        }

        return date.toLocaleString()
    }

    const SortableItem = SortableElement(({ ad, last }) => (
        <div key={ad} className="col col-3">
            <div className="card">
                {
                    ad.is_featured ?
                        <img src={ad.featured.image_url} className="card-img-top" />
                        :
                        <img src={ad.image_url} className="card-img-top" />
                }
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <p>
                            {
                                    ad.is_active ?
                                    <span className="badge badge-soft-success text-uppercase mr-2">Active</span>
                                        :
                                        <span className="badge badge-soft-secondary text-uppercase mr-2">Inactive</span>
                                }
                                {
                                    ad.is_featured ?
                                        <span className="badge badge-soft-warning text-uppercase mr-2">Featured</span>
                                        :
                                        null
                                }
                               
                            </p>
                        </div>
                        <div className="col-auto">
                            {
                                props.editSorting ?
                                    <button className="btn text-muted"><span className="fe fe-align-justify"></span></button>
                                    :
                                    <Link key={ad.id} className="btn btn-light btn-sm" to={`/ads/${ad.id}`}>edit</Link>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p className="mb-0 small">{ad.url}</p>
                        </div>
                    </div>

                    <hr />
                    {
                        ad.updated.email ?
                            <p className="card-text small text-muted mb-0">Updated by {ad.updated.email} • {parseTimestamp(ad.updated.timestamp)}</p>
                            :
                            <p className="card-text small text-muted mb-0">Created by {ad.created.email} • {parseTimestamp(ad.created.timestamp)}</p>
                    }
                </div>
            </div>
        </div>
    )
    );

    const SortableList = SortableContainer(({ items }) => {
        return (
            <div className="row">
                {items.map((ad, index) => (
                    <SortableItem disabled={!props.editSorting} key={`item-${ad.id}`} index={index} ad={ad} last={index === items.length - 1} />
                ))}
            </div>
        );
    });

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let list = data.apps;
        list = arrayMove(list, oldIndex, newIndex);
        setData({ list: list });
        props.onSort(list)
    };

    return (
        <div className="">
            <div className="">
                {/* distance={2} is very important as it will make any link in a row clickable */}
                <SortableList distance={2} items={data.list} onSortEnd={onSortEnd}></SortableList>
            </div>
        </div>
    );
}
