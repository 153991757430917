import React from "react";
import * as api from '../../services/app'
import List from './list.js'
import { Link } from "react-router-dom";

function Apps() {
    const [data, setData] = React.useState({ apps: [], isFetching: false });
    const [editing, setEditing] = React.useState(false);
    const [originalState, setOriginalState] = React.useState([]);

    function loadData() {
        api.fetchApps()
            .then(data => {
                setData({ apps: data, isFetching: false });
            })
            .catch(error => {
                setData({ apps: [], isFetching: false });
            })
    }

    function editSorting() {
        setOriginalState(data.apps.slice());
        setEditing(true);
    }

    function cancelSorting() {
        setEditing(false);
        console.log(originalState);
        setData({ apps: originalState.slice(), isFetching: false });
    }

    function onSort(list) {
        setData({ apps: list, isFetching: false });
    }

    function updateSorting() {
        setOriginalState([]);
        var sortedIds = data.apps.map(i => {
            return i.id
        });
        api.updateSort(sortedIds)
            .then(data => {
                setEditing(false);
            })
            .catch(error => {
                alert(error);
            })
    }

    React.useEffect(() => {
        setData({ apps: [], isFetching: true });
        loadData()
    }, []);

    return (
        <div>
            <div className="header">
                <div className="container-fluid">
                    <div className="header-body">
                        <div className="row align-items-end">
                            <div className="col">
                                <h1 className="header-title">Apps/Services</h1>
                            </div>
                            <div className="col-auto">
                                {
                                    editing ?
                                        <div>
                                            <button className="btn btn-sm btn-primary ml-3" onClick={updateSorting}><i className="fe fe-save mr-2"></i>Update</button>
                                            <button className="btn btn-sm btn-outline-secondary ml-3" onClick={cancelSorting}>Cancel</button>
                                        </div>
                                        :
                                        <div>
                                            <Link id="form-button" className="btn btn-sm btn-outline-primary" to="/apps/new"><i className="fe fe-plus mr-2"></i> Add new App</Link>
                                            <button className="btn btn-sm btn-outline-primary ml-3" onClick={editSorting}><i className="fe fe-align-justify mr-2"></i>Edit sorting</button>
                                        </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                {
                    data.apps.length === 0 ?
                        null
                        :
                        <List list={data.apps} editSorting={editing} onSort={onSort}></List>
                }

            </div>
        </div>
    );
}

export default Apps;