import React from 'react'
import { Link } from "react-router-dom";

export default function List(props) {

    const [data, setData] = React.useState({ list: [] });

    React.useEffect(() => {
        setData({ list: props.data })
    }, [props.data]);

    return (
        <div>
            {data.list.map((item, index) => (
                <div className="card" key={item.id}>
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-auto">
                                <a href="#!" className="avatar avatar-lg">
                                    <img src={item.icon_image_url} alt={item.name} className="avatar-img" />
                                </a>
                            </div>
                            <div className="col ml-n2">
                                <h4 className="card-title mb-1 name">
                                <Link key={item.id} to={`/tokens/${item.id}`}> {item.name} — {item.symbol}</Link> 
                                {
                                    item.is_featured ?
<span className="ml-2 badge badge-soft-success">FEATURED</span>
: null
                                }
                                
                                </h4>
                                <p className="card-text small text-muted  text-truncate">
                                    <a href={`https://simpleledger.info/#token/${item.id}`}
                                        target="blank">{item.id}</a>
                                </p>
                            </div>
                            <div className="col-auto">
                                <Link key={item.id} className="btn btn-light btn-sm" to={`/tokens/${item.id}`}>edit</Link>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );

}