import React from 'react'
import * as api from '../../services/token'
import { _upload } from '../../services/http.js'
import { _get } from '../../services/http'


export default function TokenForm(props) {


    const [input, setInput] = React.useState(
        {
            id: "",
            name: "",
            symbol: "",
            decimals: 0,
            icon_image_url: "",
            is_featured: false,
            url: "",
        });

    const [featured, setFeatured] = React.useState(
        {
            time: {
                from: 0,
                to: 0
            },
            fromDate: "2020-01-01",
            fromTime: "00:00:00",
            toDate: "2030-01-01",
            toTime: "00:00:00"
        }
    );
    function timestampToTime(unixTimestamp) {
        let now = new Date();

        if (unixTimestamp !== 0) {
            now = new Date(unixTimestamp * 1000);
        }

        let hours = now.getUTCHours().toString().padStart(2, '0');
        let minutes = now.getUTCMinutes().toString().padStart(2, '0');
        let seconds = now.getUTCSeconds().toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    }

    function timestampToDate(unixTimestamp) {
        var now = new Date();
        if (unixTimestamp !== 0) {
            now = new Date(unixTimestamp * 1000);
        }

        let year = now.getUTCFullYear().toString().padStart(2, '0');
        let month = (now.getUTCMonth() + 1).toString().padStart(2, '0');  // Months in JavaScript are returned as a 0 based value.
        let date = now.getUTCDate().toString().padStart(2, '0');
        return `${year}-${month}-${date}`
    }

    function loadData(id) {
        api.getDetail(id)
            .then(data => {
                if (data.is_featured === true) {
                    data.featured.fromDate = timestampToDate(data.featured.time.from)
                    data.featured.fromTime = timestampToTime(data.featured.time.from)
                    data.featured.toDate = timestampToDate(data.featured.time.to)
                    data.featured.toTime = timestampToTime(data.featured.time.to)
                    setFeatured(data.featured)
                }

                setInput(data)
            })
            .catch(error => {
                props.history.push('/tokens');
            })
    }

    const deleteItem = (e) => {
        e.preventDefault();
        api.removeItem(input.id)
            .then(data => {
                props.history.push('/tokens')
            })
            .catch(error => {
                alert(error);
            })
    }

    const save = (e) => {
        e.preventDefault();

        if (input.is_featured === true) {
            if (featured.fromDate !== "" && featured.fromTime) {
                let unixTimeMilliseconds = Date.parse(`${featured.fromDate} ${featured.fromTime} UTC`);
                if (isNaN(unixTimeMilliseconds) === false) {
                    let unixtimestamp = unixTimeMilliseconds / 1000;
                    featured.time.from = unixtimestamp
                }
            }

            if (featured.toDate !== "" && featured.toTime) {
                let unixTimeMilliseconds = Date.parse(`${featured.toDate} ${featured.toTime} UTC`);
                if (isNaN(unixTimeMilliseconds) === false) {
                    let unixtimestamp = unixTimeMilliseconds / 1000;
                    featured.time.to = unixtimestamp
                }
            }
        }

        onSave(input);
    }

    function onSave(item) {

        if (props.match.params.id !== "new") {
            //update
            api.updateToken(item.id, item.name, item.symbol, item.decimals, item.icon_image_url, item.is_featured, featured.time.from, featured.time.to, item.url)
                .then(data => {
                    props.history.push('/tokens')
                })
                .catch(error => {
                    alert(error);
                })
        } else {
            //new 
            api.newToken(item.id, item.name, item.symbol, item.decimals, item.icon_image_url, item.is_featured, featured.time.from, featured.time.to, item.url)
                .then(data => {
                    props.history.push('/tokens')
                })
                .catch(error => {
                    alert(error);
                })
        }
    }

    const handleDateTimeInputChange = (e) => {
        const { name, value } = e.target
        setFeatured({ ...featured, [name]: value })
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        if (e.target.type === "checkbox") {
            setInput({ ...input, [name]: e.target.checked })
            return
        }

        setInput({ ...input, [name]: value })
        //when user pastes token id then try to auto fill the rest
        if (name === "id") {
            _get(`https://slp.api.wallet.bitcoin.com/v3/slp/details/${value}`)
                .then(data => {
                    setInput({
                        ...input,
                        id: value,
                        name: data.name,
                        symbol: data.symbol,
                        decimals: data.decimals
                    })
                })
                .catch(error => {
                    alert("Invalid token ID")
                })
        }
    }

    const onFileSelect = (e) => {
        const { name } = e.target
        var f = e.target.files[0];
        let formData = new FormData();
        formData.append("file", f);
        _upload("POST", "/image/upload", formData)
            .then(function (res) {
                setInput({ ...input, [name]: res.result.data })
            }, function (e) {
                alert("Error submitting form!");
            });
    }

    React.useEffect(() => {
        if (props.match.params.id !== "new") {
            loadData(props.match.params.id)
        }
    }, [props.match.params.id]);


    return (
        <div className="container-fluid">
            <div className="header">

                <div className="header-body">
                    <div className="row align-items-end">
                        <div className="col">
                            <h1 className="header-title">Verified SLP Tokens</h1>
                        </div>
                        <div className="col-auto">
                            <button className="btn btn-sm btn-outline-secondary" onClick={(e) => { props.history.goBack() }}><i className="fe fe-chevron-left"></i>  Back</button>
                        </div>
                    </div>
                </div>
            </div>

            <form className="" onSubmit={save}>
                <div className="card">
                    <div className="card-header">
                        <div className="row align-items-center">
                            <div className="col">


                                <h4 className="card-header-title">Featured token 🎉</h4>

                            </div>
                            <div className="col-auto mr-n3">


                                <span className="text-muted">Featured token: </span>

                            </div>
                            <div className="col-auto">


                                <div className="custom-control custom-switch">
                                    <input type="checkbox" name="is_featured" className="custom-control-input" id="cardToggle" checked={input.is_featured} onChange={handleInputChange} />
                                    <label className="custom-control-label" htmlFor="cardToggle"></label>
                                </div>

                            </div>
                        </div>

                    </div>
                    {
                        input.is_featured ?
                            <div className="card-body">
                                <div className="">
                                    <p className="text-muted">Specify start date and end date</p>
                                    <div className="row">
                                        <div className="col col-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="mb-1">
                                                    From date <span className="text-danger">*</span> <span className="small text-muted">(ISO format year-month-date e.g. 2018-11-15)</span>
                                                </label>
                                                <input type="date" className="form-control" name="fromDate" value={featured.fromDate} onChange={handleDateTimeInputChange} required />
                                            </div>
                                        </div>
                                        <div className="col col-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="mb-1">
                                                    Time <span className="text-danger">*</span> <span className="small text-muted">(hour:minute:second in 24-hour format GMT e.g. 16:40:00)</span>
                                                </label>
                                                <input type="text" className="form-control" name="fromTime" value={featured.fromTime} onChange={handleDateTimeInputChange} required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="mb-1">
                                                    To date <span className="text-danger">*</span> <span className="small text-muted">(ISO format year-month-date e.g. 2018-11-15)</span>
                                                </label>
                                                <input type="date" className="form-control" name="toDate" value={featured.toDate} onChange={handleDateTimeInputChange} required />
                                            </div>
                                        </div>
                                        <div className="col col-6 col-lg-6">
                                            <div className="form-group">
                                                <label className="mb-1">
                                                    Time <span className="text-danger">*</span> <span className="small text-muted">(hour:minute:seconds in 24-hour format GMT e.g. 16:40:00)</span>
                                                </label>
                                                <input type="text" className="form-control" name="toTime" value={featured.toTime} onChange={handleDateTimeInputChange} required />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            : null
                    }

                </div>

                <div className="card">

                    <div className="card-body">



                        <div className="form-group">

                            <label>
                                SLP Token ID <span className="text-danger">*</span>
                            </label>
                            <div className=" input-group ">
                                <input type="text" name="id" className="form-control form-control-appended" value={input.id} onChange={handleInputChange} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label>
                                        Name <span className="text-danger">*</span>
                                    </label>
                                    <input type="text" name="name" className="form-control" value={input.name} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label>
                                        Symbol <span className="text-danger">*</span>
                                    </label>
                                    <input type="text" name="symbol" className="form-control" value={input.symbol} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <label>
                                        Decimals <span className="text-danger">*</span>
                                    </label>
                                    <input type="number" name="decimals" className="form-control" value={input.decimals} onChange={handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label>
                                        URL
                                    </label>
                                    <input type="text" name="url" placeholder="https:// — a URL to view token detail" className="form-control" value={input.url} onChange={handleInputChange} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="form-group">
                                    <label>
                                        Icon Image URL <span className="text-danger">*</span>
                                    </label>
                                    <input type="text" name="icon_image_url" className="form-control" value={input.icon_image_url} onChange={handleInputChange} />
                                </div>
                            </div>
                            <div className="col col-auto">
                                <div className="form-group">
                                    <label>&nbsp;</label>
                                    <div className="fallback">
                                        <div className="custom-file">
                                            <input type="file" name="icon_image_url" className="custom-file-input" accept="image/*" onChange={onFileSelect} />
                                            <label className="custom-file-label" htmlFor="projectCoverUploads">Choose image file</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div className="card-footer row">
                        <div className="col">
                            <button type="submit" className="btn btn-primary mr-2">Save</button>
                            <button type="button" className="btn btn-outline-secondary" onClick={(e) => { props.history.goBack() }}>Cancel</button>
                        </div>
                        {
                            props.match.params.id !== "new"
                                ? <div className="col  text-right"><button type="button" className="btn btn-outline-danger" onClick={deleteItem}>Delete</button></div>
                                : null
                        }

                    </div>
                </div>




            </form>
        </div>
    );
}