import React from "react";
import * as api from '../../services/apikey'


function ApiKeys(props) {
    const [data, setData] = React.useState({ list: [], isFetching: false });

    function parseTimestamp(unixTimestamp) {
        var date = new Date();

        if (unixTimestamp !== 0) {
            date = new Date(unixTimestamp * 1000);
        }

        return date.toLocaleString()
    }
    function loadData() {
        api.fetch()
            .then(data => {
                setData({ list: data, isFetching: false });
            })
            .catch(error => {
                setData({ list: [], isFetching: false });
            })
    }

    const deleteItem = (e, id) => {
        e.preventDefault();
        api.remove(id)
            .then(data => {
                loadData()
            })
            .catch(error => {
                alert(error);
            })
    }

    const addNewKey = (e) => {
        e.preventDefault()
        api.newKey()
            .then(data => {
                loadData()
            })
            .catch(error => {
                alert(error);
            })
        
    }

    React.useEffect(() => {
        setData({ list: [], isFetching: true });
        loadData()
    }, []);

    return (
        <div>
            <div className="header">
                <div className="container-fluid">
                    <div className="header-body">
                        <div className="row align-items-end">
                            <div className="col">
                                <h1 className="header-title">API keys</h1>
                            </div>
                            <div className="col-auto">
                                <div>
                                    <button id="form-button" className="btn btn-sm btn-outline-primary" onClick={(e) => {addNewKey(e)}} ><i className="fe fe-plus mr-2"></i> Add new key</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
            
                <div className="card">
                    <div className="card-body">
                        {data.list.map((item, index) => {
                            return (
                                <div key={item}>
                                    <div className="row">
                                        <div className="col">
                                            {item.key}
                            <p className="small text-muted mb-0">Created by {item.created.email} • {parseTimestamp(item.created.timestamp)}</p>
                                        </div>
                                        <div className="col text-right">
                                            <button type="button" className="btn btn-sm btn-outline-danger" onClick={(e) => { deleteItem(e, item.key) }}>Delete</button>
                                        </div>
                                    </div>
                                    {
                                        index === data.list.length - 1 ?
                                            null
                                            : <hr />
                                    }

                                </div>
                            );
                        })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApiKeys;