import React from "react";
import List from './list.js'
import { Link } from "react-router-dom";
import * as api from '../../services/token'

function Tokens() {

    const [data, setData] = React.useState({ list: [], isFetching: false });

    function loadData() {
        api.fetchTokens()
            .then(data => {
                setData({ list: data, isFetching: false });
            })
            .catch(error => {
                setData({ list: [], isFetching: false });
            })
    }
    React.useEffect(() => {
        setData({ list: [], isFetching: true });
        loadData()
    }, []);

    return (
        <div>
            <div className="header">
                <div className="container-fluid">
                    <div className="header-body">
                        <div className="row align-items-end">
                            <div className="col">
                                <h1 className="header-title">
                                    Verified SLP Tokens
                            </h1>
                            </div>
                            <div className="col-auto">
                            <Link id="form-button" className="btn btn-sm btn-outline-primary" to="/tokens/new"><i className="fe fe-plus"></i> Add new verified SLP token</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <List data={data.list}></List>
            </div>
        </div>
    );
}



export default Tokens;