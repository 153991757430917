import {_request} from '../http'


export async function newKey() {
    return _request("POST", "/apikeys", null)
}

export async function remove(id) {
    return _request("DELETE", `/apikeys/${id}`, null)
}

export async function fetch() {
    return _request("GET", "/apikeys", null)
}

