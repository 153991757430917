import * as Constants from '../constants'
import * as Account from '../account';

export async function _get(url) {
    var acc = Account.getUserAccount();
    var options = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        },
    }
    const response = await fetch(url, options );

    let status = response.status;

    if (status !== 200) {
        let text = await response.text()
        throw new Error(text);
    }

    let data = await response.json()
    return data;
}


export async function _request(method, resourceUrl, postBody) {
    var acc = Account.getUserAccount();
    var options = {
        method: method,
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${acc.idToken}`,
        },
        body:
            postBody !== null && postBody !== undefined
                ? JSON.stringify(postBody)
                : null,
    };
    const response = await fetch(Constants.API_BASE_URL + resourceUrl, options );

    let status = response.status;
    if (status === 401) {
        //unautorized
        //clear sessionStorage
        sessionStorage.removeItem(Account.accountSessionKey)
        window.location.href = "/"
        return null;
    }

    if (status !== 200) {
        let text = await response.text()
        throw new Error(text);
    }

    let data = await response.json()
    return data;
}


export async function _upload(method, resourceUrl, postBody) {
    var acc = Account.getUserAccount();
    var options = {
        method: method,
        headers: {
            'Authorization': `Bearer ${acc.idToken}`
        },
        body: postBody
    }
    const response = await fetch(Constants.API_BASE_URL + resourceUrl, options );

    let status = response.status;
    if (status === 401) {
        //unautorized
        //clear sessionStorage
        sessionStorage.removeItem(Account.accountSessionKey)
        window.location.href = "/"
        return null;
    }

    if (status !== 200) {
        let text = await response.text()
        throw new Error(text);
    }

    let data = await response.json()
    return data;
}
