import {_request} from '../http'

export async function newFeaturedBusiness(title, description, url, featured_image_url, isActive, fromTime, toTime, logo_image_url) {
    const postData = {
        title: title,
        description: description,
        url: url,
        featured_image_url: featured_image_url,
        logo_image_url: logo_image_url,
        is_active: isActive,
        time: {
            from: fromTime,
            to: toTime
        }
    }
    
 return  _request("POST", "/featuredbusinesses", postData)
}

export async function updateFeaturedBusiness(id, title, description, url, featured_image_url, isActive, fromTime, toTime, logo_image_url) {
    const postData = {
        id: id,
        title: title,
        description: description,
        url: url,
        featured_image_url: featured_image_url,
        logo_image_url: logo_image_url,
        is_active: isActive,
        time: {
            from: fromTime,
            to: toTime
        }
    }
    return  _request("POST", `/featuredbusinesses/${id}`, postData)
}


export async function fetchFeaturedBusinesses() {
    return  _request("GET", `/featuredbusinesses/`, null)
}

export async function getDetail(id) {
    return  _request("GET", `/featuredbusinesses/${id}`, null)
}

export async function removeItem(id) {
    return  _request("DELETE", `/featuredbusinesses/${id}`, null)
}

export async function updateSort(list) {
    const postData = {
        list : list
    }
    return _request("POST", "/featuredbusinesses/sort", postData)
}