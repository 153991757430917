import React from "react";
import * as api from '../../services/featuredbusiness'
import List from './list.js'
import {Link} from "react-router-dom";

function FeaturedBusinesses() {
    const [data, setData] = React.useState({ list: [], isFetching: false });    
    const [editing, setEditing] = React.useState(false);
    const [originalState, setOriginalState] = React.useState([]);    
    
    function loadData() {
        api.fetchFeaturedBusinesses()
        .then(data => {
            setData({ list: data, isFetching: false });
        })
        .catch(error => {
            console.log("error " + error);
            setData({ list: [], isFetching: false });
        })
    }
    React.useEffect(() => {
        setData({ list: [], isFetching: true });
        loadData();
    }, []);

    function editSorting() {
        setOriginalState(data.list.slice());
        setEditing(true);
    }

    function cancelSorting() {
        setEditing(false);
        setData({ list: originalState.slice(), isFetching: false });
    }

    function onSort(list) {
        setData({ list: list, isFetching: false });
    }

    function updateSorting() {
        setOriginalState([]);
        var sortedIds = data.list.map(i => {
            return i.id
        });
        api.updateSort(sortedIds)
        .then(data => {
            setEditing(false);   
        })
        .catch(error => {
            alert(error);
        })   
    }

    return (
        <div>
            <div className="header">
                <div className="container-fluid">
                    <div className="header-body">
                        <div className="row align-items-end">
                            <div className="col">
                                <h1 className="header-title">
                                    Featured Businesses
                            </h1>
                            </div>
                            <div className="col-auto">
                            
                            {
                                    editing ?
                                    <div>
                                    <button className="btn btn-sm btn-primary ml-3" onClick={updateSorting}><i className="fe fe-save mr-2"></i>Update</button>
                                    <button className="btn btn-sm btn-outline-secondary ml-3" onClick={cancelSorting}>Cancel</button>
                                    </div>
                                    :
                                    <div>
                                    <Link id="form-button" className="btn btn-sm btn-outline-primary" to="/featured/new"><i className="fe fe-plus"></i> Add new featured business</Link>
                                <button className="btn btn-sm btn-outline-primary ml-3" onClick={editSorting}><i className="fe fe-align-justify mr-2"></i>Edit sorting</button>
                                </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <List list={data.list} editSorting={editing} onSort={onSort}></List>
            </div>
        </div>
    );
}



export default FeaturedBusinesses;