

  export const accountSessionKey = "_wd";
export  const getUserAccount = () => {
    var a = JSON.parse(sessionStorage.getItem(accountSessionKey))
    if (a === null || a.idToken === undefined) {
        return null;
    }
    return JSON.parse(sessionStorage.getItem(accountSessionKey))
  }



