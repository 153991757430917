import {_request} from '../http'


export async function newApp(title, description, url, isActive, isLinkEnabled, fromTime, toTime, logo_image_url, is_featured, featured_info, geo_target) {
    const postData = {
        title: title,
        description: description,
        url: url,
        is_active: isActive,
        is_link_enabled: isLinkEnabled,
        time: {
            from: fromTime,
            to: toTime
        },
        logo_image_url: logo_image_url,
        is_featured: is_featured,
        featured: featured_info,
        geo_target: geo_target
    }
    return _request("POST", "/apps", postData)
}

export async function updateApp(id, title, description, url, isActive, isLinkEnabled, fromTime, toTime, logo_image_url, is_featured, featured_info, geo_target) {
    const postData = {
        id: id,
        title: title,
        description: description,
        url: url,
        is_active: isActive,
        is_link_enabled: isLinkEnabled,
        time: {
            from: fromTime,
            to: toTime
        },
        logo_image_url: logo_image_url,
        is_featured: is_featured,
        featured: featured_info,
        geo_target: geo_target
    }
    return _request("POST", `/apps/${id}`, postData)
}

export async function getAppDetail(id) {
    return _request("GET", `/apps/${id}`, null)
}

export async function removeApp(id) {
    return _request("DELETE", `/apps/${id}`, null)
}

export async function fetchApps() {
    return _request("GET", "/apps", null)
}

export async function updateSort(list) {
    const postData = {
        list : list
    }
    return _request("POST", "/apps/sort", postData)
}