import {_request} from '../http'

export async function newToken(id, name, symbol, decimals, iconImageURL, is_featured, fromTime, toTime, url) {
    const postData = {
        id: id,
        name: name,
        symbol: symbol,
        decimals: Number(decimals),
        icon_image_url: iconImageURL,
        is_featured:is_featured,
        featured: {
            time: {
                from: fromTime,
                to: toTime
            }
        },
        url: url
    }
    return _request("POST", "/tokens", postData)
}

export async function updateToken(id, name, symbol, decimals, iconImageURL, is_featured, fromTime, toTime, url) {
    const postData = {
        id: id,
        name: name,
        symbol: symbol,
        decimals: Number(decimals),
        icon_image_url: iconImageURL,
        is_featured:is_featured,
        featured: {
            time: {
                from: fromTime,
                to: toTime
            }
        },
        url: url
    }
    return _request("POST", `/tokens/${id}`, postData)
}

export async function getDetail(id) {
    return _request("GET", `/tokens/${id}`, null)
}

export async function removeItem(id) {
    return _request("DELETE", `/tokens/${id}`, null)
}

export async function fetchTokens() {
    return _request("GET", "/tokens", null)
}