import React from "react";
import * as api from '../../services/link'
import List from './list.js'
import { Link } from "react-router-dom";

function Links() {
    const [data, setData] = React.useState({ links: [], isFetching: false });
    const [editing, setEditing] = React.useState(false);
    const [originalState, setOriginalState] = React.useState([]);    

    function loadData() {
        api.fetchLinks()
            .then(data => {
                setData({ links: data, isFetching: false });
            })
            .catch(error => {
                setData({ links: [], isFetching: false });
            })
    }

    function editSorting() {
        setOriginalState(data.links.slice());
        setEditing(true);
    }

    function cancelSorting() {
        setEditing(false);
        console.log(originalState);
        setData({ links: originalState.slice(), isFetching: false });
    }

    function onSort(list) {
        setData({ links: list, isFetching: false });
    }

    function updateSorting() {
        setOriginalState([]);
        var sortedIds = data.links.map(i => {
            return i.id
        });
        api.updateSort(sortedIds)
        .then(data => {
            setEditing(false);   
        })
        .catch(error => {
            alert(error);
        })   
    }

    React.useEffect(() => {
        setData({ links: [], isFetching: true });
        loadData()
    }, []);

    return (
        <div>
            <div className="header">
                <div className="container-fluid">
                    <div className="header-body">
                        <div className="row align-items-end">
                            <div className="col">
                            <h1 className="header-title">Links</h1>
                            </div>
                            <div className="col-auto">
                                {
                                    editing ?
                                    <div>
                                    <button className="btn btn-sm btn-primary ml-3" onClick={updateSorting}><i className="fe fe-save mr-2"></i>Update</button>
                                    <button className="btn btn-sm btn-outline-secondary ml-3" onClick={cancelSorting}>Cancel</button>
                                    </div>
                                    :
                                    <div>
                                    <Link id="form-button" className="btn btn-sm btn-outline-primary" to="/links/new"><i className="fe fe-plus mr-2"></i> Add new Link</Link>
                                <button className="btn btn-sm btn-outline-primary ml-3" onClick={editSorting}><i className="fe fe-align-justify mr-2"></i>Edit sorting</button>
                                </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                {
                    data.links.length === 0 ?
                    null
                    :
                    <List list={data.links} editSorting={editing} onSort={onSort}></List>
                }
                
            </div>
        </div>
    );
}

export default Links;